// npm
import React, { memo } from 'react'
import { Field, FieldProps } from 'formik'
import { InputAdornment, styled } from '@mui/material'
import {
  AiOutlineExclamationCircle,
  AiOutlineCheckCircle,
} from 'react-icons/ai'

// atoms
import TextField, { TextFieldProps } from '@atoms/inputs/TextField'

type Props = Omit<TextFieldProps, 'placeholder' | 'fieldTheme'> & {
  name: string
  label?: string
  placeholder?: string
  noAdornment?: boolean
}

const ErrorIcon = styled(AiOutlineExclamationCircle)(({ theme }) => ({
  fontSize: 'inherit',
  color: theme.palette.error.main,
}))
const SuccessIcon = styled(AiOutlineCheckCircle)(({ theme }) => ({
  fontSize: 'inherit',
  color: theme.palette.success.main,
}))

const FormikTextField = ({
  name,
  label,
  placeholder,
  noAdornment,
  ...props
}: Props) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        const hasError = meta.touched === true && meta.error !== undefined
        const valid = meta.touched && !meta.error

        let inputProps = {}
        if (!noAdornment) {
          if (hasError) {
            inputProps = {
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end" sx={{ mr: 1 }}>
                    <ErrorIcon />
                  </InputAdornment>
                ),
              },
            }
          }
          if (valid) {
            inputProps = {
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end" sx={{ mr: 1 }}>
                    <SuccessIcon />
                  </InputAdornment>
                ),
              },
            }
          }
        }

        return (
          <TextField
            placeholder={placeholder ? placeholder : label}
            label={hasError ? meta.error : label}
            inputProps={{
              ...field,
            }}
            valid={valid ? true : undefined}
            error={hasError}
            value={field.value}
            InputLabelProps={
              hasError
                ? {
                    shrink: hasError,
                  }
                : {}
            }
            {...inputProps}
            {...props}
          />
        )
      }}
    </Field>
  )
}

export default memo(FormikTextField)
