// npm
import React, { memo } from 'react'
import {
  TextField as TextFieldBase,
  TextFieldProps as TextFieldBaseProps,
  alpha,
  styled,
} from '@mui/material'

interface TextFieldCoreProps {
  fieldTheme: 'standard-dark'
  valid?: boolean
}

export type TextFieldProps = TextFieldCoreProps &
  TextFieldBaseProps & {
    placeholder: string
  }

const StyledTextField = styled(TextFieldBase, {
  shouldForwardProp: (prop) => prop !== 'fieldTheme',
})<TextFieldCoreProps>(({ fieldTheme, valid, theme }) => ({
  ...(fieldTheme === 'standard-dark' && {
    ['.MuiInputLabel-formControl']: {
      color: alpha(theme.palette.text.primary, 0.6),
    },
    ['.MuiInputBase-root']: {
      color: theme.palette.text.primary,
      ['&:before']: {
        borderBottomColor: alpha(theme.palette.text.primary, 0.6),
      },
      ['&:after']: {
        borderBottomColor: alpha(theme.palette.text.primary, 0.6),
      },
      ['&.Mui-error']: {
        ['&:before']: {
          borderBottomColor: theme.palette.error.main,
        },
        ['&:after']: {
          borderBottomColor: theme.palette.error.main,
        },
      },
    },
    ['.MuiInputBase-input']: {
      '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active':
        {
          WebkitBoxShadow: `0 0 0 30px ${theme.palette.secondary.dark} inset`,
          WebkitTextFillColor: alpha(theme.palette.text.primary, 0.6),
          fontSize: '1.6rem',
        },
    },
    ['.MuiInputLabel-root']: {
      ['&.Mui-error']: {
        color: theme.palette.error.main,
      },
    },
    ['.MuiFormLabel-root']: {
      transform: 'translate(0, 19px) scale(1)',
      ['&.MuiInputLabel-shrink']: {
        transform: 'translate(0, -8px) scale(0.9)',
        color: 'white',
      },
    },
  }),
}))

const TextField = ({ placeholder, ...props }: TextFieldProps) => {
  return (
    <StyledTextField
      label={placeholder}
      variant="standard"
      fieldTheme="standard-dark"
      autoComplete="off"
      {...props}
    />
  )
}

export default memo(TextField)
