// npm
import React, { memo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Grid, useTheme } from '@mui/material'
import { Formik, Form, FormikHelpers } from 'formik'
import { object, string } from 'yup'

// components
import Button from '@atoms/buttons/Button'
import FormikTextField from '@molecules/formik/FormikTextField'

// context
import { useMessage } from '@context/MessageContext'

// helpers
import encodeForm from '@helpers/encodeForm'

interface FormValues {
  name: string
  email: string
  message: string
}

const EnquiryForm = () => {
  const theme = useTheme()
  const { dispatch } = useMessage()
  const data = useStaticQuery<Queries.EnquiryFormQuery>(graphql`
    query EnquiryForm {
      datoCmsEnquiryForm {
        nameFieldLabel
        nameRequiredMessage
        emailFormatMessage
        emailLabel
        emailRequiredMessage
        messageLabel
        messageRequiredMessage
        buttonLabel
        submissionErrorMessage
        submissionSuccessMessage
      }
    }
  `)
  const d = data.datoCmsEnquiryForm

  const initialValues: FormValues = {
    name: '',
    email: '',
    message: '',
  }

  const validationSchema = object().shape({
    name: string().required(d.nameRequiredMessage),
    email: string()
      .email(d.emailFormatMessage)
      .required(d.emailRequiredMessage),
    message: string().required(d.messageRequiredMessage),
  })
  const formName = 'Enquiry'

  const handleSubmit = (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encodeForm({ 'form-name': formName, ...values }),
    })
      .then(() => {
        dispatch({
          type: 'SET_MESSAGE',
          payload: {
            message: d.submissionSuccessMessage,
            status: 'success',
          },
        })
        actions.resetForm()
      })
      .catch(() => {
        dispatch({
          type: 'SET_MESSAGE',
          payload: {
            message: d.submissionErrorMessage,
            status: 'error',
          },
        })
      })
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values: FormValues, actions: FormikHelpers<FormValues>) => {
        handleSubmit(values, actions)
      }}
    >
      <Form
        name={formName}
        data-netlify={true}
        netlify-honeypot="botField"
        noValidate
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <FormikTextField name="name" label="Name" fullWidth sx={{ m: 0 }} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormikTextField
              name="email"
              label="Email Address"
              type="email"
              fullWidth
              sx={{ mb: 0, mt: { xs: 0, sm: 1, lg: 0 } }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormikTextField
              name="message"
              label="Message"
              multiline
              rows={3}
              fullWidth
              sx={{ mb: 0, mt: { xs: 0, sm: 1, lg: 2 } }}
            />
          </Grid>
        </Grid>

        <Button
          type="submit"
          sx={{ color: theme.palette.text.primary, mt: { xs: 4, md: 5 } }}
        >
          {d.buttonLabel}
        </Button>
      </Form>
    </Formik>
  )
}

export default memo(EnquiryForm)
